<template>
    <div class="constellation-container">
        <!-- 星座选择区域 -->
        <div class="constellation-selection" v-if="isShow">
            <div
                    class="constellation-item"
                    v-for="(item, index) in constellation"
                    :key="index"
                    @click="selectZodiacSign(item)"
                    :class="{ 'active': zodiacSign && zodiacSign.zodiacSign === item.zodiacSign }"
            >
                <img :src="item.img" alt="星座图标"/>
                <p class="zodiac-name">{{ item.zodiacSign }}</p>
                <p class="zodiac-range">{{ item.range }}</p>
            </div>
        </div>
        <div class="constellation2-selection" v-if="!isShow">
            <div
                class="constellation2-item"
                v-for="(item, index) in constellation"
                :key="index"
                @click="selectZodiacSign(item)"
                :class="{ 'active': zodiacSign && zodiacSign.zodiacSign === item.zodiacSign }"
            >
                <img :src="item.img" alt="星座图标"/>
            </div>
        </div>

        <!-- 运势展示区域 -->
        <div v-if="zodiacSign" class="fortune-display">
            <div class="fortune-header">
                <img :src="zodiacSign.img" alt="星座图标"/>
                <h2>{{ zodiacSign.zodiacSign }}运势</h2>
            </div>

            <!-- 运势详情 -->
            <div class="fortune-details">
                <div class="tag" style="font-weight: bolder">整体运势介绍</div>
                <div class="detail-item">
                    <p><strong>幸运色:</strong> <span class="highlight">{{ zodiacSign.luckyColor }}</span></p>
                    <p><strong>幸运数字:</strong> <span class="highlight">{{ zodiacSign.luckyNumber }}</span></p>
                    <p><strong>日期范围:</strong> <span class="highlight">{{ zodiacSign.range }}</span></p>
                    <p><strong>主宰星座特性:</strong> <span class="highlight">{{ zodiacSign.characteristics }}</span>
                    </p>
                    <p><strong>所属元素:</strong> <span class="highlight">{{ zodiacSign.element }}</span></p>
                    <p><strong>性格特质:</strong> <span class="highlight">{{ zodiacSign.personalityTraits }}</span></p>
                    <p><strong>吉祥宝石:</strong> <span class="highlight">{{ zodiacSign.auspiciousGemstones }}</span>
                    </p>
                    <p><strong>性格优缺点:</strong> 优点: <span class="highlight">{{ zodiacSign.merit }}</span> / 缺点:
                        <span class="highlight">{{ zodiacSign.shortcoming }}</span></p>
                    <p><strong>性格分析:</strong> <span class="highlight">{{ zodiacSign.personalityAnalysis }}</span>
                    </p>
                    <p><strong>具体特点:</strong> <span class="highlight">{{
                        zodiacSign.specificCharacteristics
                        }}</span></p>
                    <p><strong>感情和人际关系:</strong> <span class="highlight">{{ zodiacSign.relationship }}</span></p>
                    <p><strong>总结:</strong> <span class="highlight">{{ zodiacSign.summary }}</span></p>
                </div>
            </div>

            <div class="fortune2-tags">
                <!-- 今日运势部分 -->
                <div class="fortune-section">
                    <h3>{{ zodiacSign.todayFortune.date }} 今日运势</h3>
                    <div class="fortune-tags">
                        <p class="tag">综合指数: <span class="highlight">{{ zodiacSign.todayFortune.all }}</span></p>
                        <p class="tag">幸运色: <span class="highlight">{{ zodiacSign.todayFortune.color }}</span></p>
                        <p class="tag">健康指数: <span class="highlight">{{ zodiacSign.todayFortune.health }}</span></p>
                        <p class="tag">爱情指数: <span class="highlight">{{ zodiacSign.todayFortune.love }}</span></p>
                        <p class="tag">财运指数: <span class="highlight">{{ zodiacSign.todayFortune.money }}</span></p>
                        <p class="tag">幸运数字: <span class="highlight">{{ zodiacSign.todayFortune.number }}</span></p>
                        <p class="tag">速配星座: <span class="highlight">{{ zodiacSign.todayFortune.qfriend }}</span>
                        </p>
                        <p class="tag">工作指数: <span class="highlight">{{ zodiacSign.todayFortune.work }}</span></p>
                    </div>
                    <p><strong>今日概述:</strong> <span class="highlight">{{ zodiacSign.todayFortune.summary }}</span>
                    </p>
                </div>

                <!-- 明日运势部分 -->
                <div class="fortune-section">
                    <h3>{{ zodiacSign.tomorrowFortune.date }} 明日运势</h3>
                    <div class="fortune-tags">
                        <p class="tag">综合指数: <span class="highlight">{{ zodiacSign.tomorrowFortune.all }}</span></p>
                        <p class="tag">幸运色: <span class="highlight">{{ zodiacSign.tomorrowFortune.color }}</span></p>
                        <p class="tag">健康指数: <span class="highlight">{{ zodiacSign.tomorrowFortune.health }}</span>
                        </p>
                        <p class="tag">爱情指数: <span class="highlight">{{ zodiacSign.tomorrowFortune.love }}</span>
                        </p>
                        <p class="tag">财运指数: <span class="highlight">{{ zodiacSign.tomorrowFortune.money }}</span>
                        </p>
                        <p class="tag">幸运数字: <span class="highlight">{{ zodiacSign.tomorrowFortune.number }}</span>
                        </p>
                        <p class="tag">速配星座: <span class="highlight">{{ zodiacSign.tomorrowFortune.qfriend }}</span>
                        </p>
                        <p class="tag">工作指数: <span class="highlight">{{ zodiacSign.tomorrowFortune.work }}</span>
                        </p>
                    </div>
                    <p><strong>明日概述:</strong> <span class="highlight">{{
                        zodiacSign.tomorrowFortune.summary
                        }}</span>
                    </p>
                </div>
                <!-- 本周运势部分 -->
                <div class="fortune-section">
                    <h3>{{ zodiacSign.weekFortune.date }} 本周运势</h3>
                    <h4>第{{ zodiacSign.weekFortune.weekth }}周</h4>
                    <div class="fortune2-tags">
                        <p><strong>健康运势:</strong> <span class="highlight">{{ zodiacSign.weekFortune.health }}</span>
                        </p>
                        <p><strong>爱情指数:</strong> <span class="highlight">{{ zodiacSign.weekFortune.love }}</span>
                        </p>
                        <p><strong>财运指数:</strong> <span class="highlight">{{ zodiacSign.weekFortune.money }}</span>
                        </p>
                        <p><strong>工作指数:</strong> <span class="highlight">{{ zodiacSign.weekFortune.work }}</span>
                        </p>
                    </div>
                </div>

                <!-- 本月运势部分 -->
                <div class="fortune-section">
                    <h3>{{ zodiacSign.monthFortune.date }} 本月运势</h3>
                    <div class="fortune2-tags">
                        <p><strong>综合运势:</strong> <span class="highlight">{{ zodiacSign.monthFortune.all }}</span>
                        </p>
                        <p><strong>健康运势:</strong> <span class="highlight">{{
                            zodiacSign.monthFortune.health
                            }}</span>
                        </p>
                        <p><strong>爱情运势:</strong> <span class="highlight">{{ zodiacSign.monthFortune.love }}</span>
                        </p>
                        <p><strong>财运运势:</strong> <span class="highlight">{{ zodiacSign.monthFortune.money }}</span>
                        </p>
                        <p><strong>工作运势:</strong> <span class="highlight">{{ zodiacSign.monthFortune.work }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <!-- 今年运势部分 -->
            <div class="fortune-section">
                <h3>{{ zodiacSign.yearFortune.date }} 今年运势</h3>
                <div class="fortune2-tags">
                    <p><strong>概述:</strong> <span class="highlight">{{ zodiacSign.yearFortune.all }}</span></p>
                    <p><strong>健康指数:</strong> <span class="highlight">{{ zodiacSign.yearFortune.health }}</span></p>
                    <p><strong>感情运:</strong> <span class="highlight">{{ zodiacSign.yearFortune.love }}</span></p>
                    <p><strong>财运:</strong> <span class="highlight">{{ zodiacSign.yearFortune.money }}</span></p>
                    <p><strong>事业运:</strong> <span class="highlight">{{ zodiacSign.yearFortune.work }}</span></p>
                </div>
                <p><strong>总体说明:</strong> <span class="highlight">{{ zodiacSign.yearFortune.summary }}</span></p>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* Container */
.constellation-container {
    max-width: 100%; /* 100%宽度适应各种设备 */
    margin: 0 auto;
    padding: 5vw 4vw;
    background: linear-gradient(135deg, #A7C7E7, #D2E4F7);
    border-radius: 20px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-out;
}

/* Zodiac Selection Area */
.constellation-selection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-bottom: 40px;
    animation: fadeIn 1s ease-out;
    width: 100%; /* 让选项区域宽度适应 */
    box-sizing: border-box;
}

/* Zodiac Item */
.constellation-item {
    text-align: center;
    padding: 18px;
    background: linear-gradient(135deg, #A7C7E7, #91A9D7);
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    opacity: 0;
    animation: slideUp 1s forwards;
}

/* Hover Effects */
.constellation-item:hover {
    transform: scale(1.1);
    background: linear-gradient(135deg, #91A9D7, #688FB5);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    filter: brightness(1.1);
}

.constellation-item.active {
    background: linear-gradient(135deg, #688FB5, #3D6A8B);
}

/* Hover icon effect */
.constellation-item img {
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
    transition: transform 0.3s ease, filter 0.3s ease;
    border-radius: 50%;
    border: 3px solid #A7C7E7;
}

.constellation-item:hover img {
    transform: rotate(15deg);
    filter: brightness(1.3);
}

/* Hover Text Animation */
.constellation-item p {
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    margin-top: 8px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.constellation-item:hover p {
    opacity: 1;
}

/* Zodiac Selection (Compact Version) */
.constellation2-selection {
    display: flex; /* 水平排列 */
    justify-content: center; /* 居中对齐 */
    flex-wrap: wrap; /* 如果屏幕宽度不足，自动换行 */
    gap: 12px; /* 图标之间的间距 */
    margin-bottom: 40px;
    animation: fadeIn 1s ease-out;
    width: 100%; /* 让选项区域宽度适应 */
    box-sizing: border-box;
}

/* Zodiac Item (Compact Version) */
.constellation2-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; /* 调整图标的外边距 */
    background: linear-gradient(135deg, #A7C7E7, #91A9D7);
    border-radius: 50%; /* 圆形 */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    cursor: pointer;
    width: 60px; /* 控制图标大小 */
    height: 60px; /* 控制图标大小 */
    overflow: hidden; /* 避免内容溢出 */
}

.constellation2-item img {
    width: 100%; /* 使图标充满容器 */
    height: 100%; /* 使图标充满容器 */
    border-radius: 50%; /* 圆形图片 */
    object-fit: cover; /* 保持图片比例 */
}

/* Hover Effects */
.constellation2-item:hover {
    transform: scale(1.1);
    background: linear-gradient(135deg, #91A9D7, #688FB5);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    filter: brightness(1.1);
}

.constellation2-item.active {
    background: linear-gradient(135deg, #688FB5, #3D6A8B);
}

/* Fortune Display Area */
.fortune-display {
    display: flex;
    flex-direction: column;
    animation: fadeIn 1s ease-out;
    width: 100%; /* 适配手机端 */
    box-sizing: border-box;
}

/* Fortune Header */
.fortune-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 3px solid #688FB5;
    padding-bottom: 15px;
    animation: slideIn 1s ease-out;
    width: 100%; /* 适应屏幕宽度 */
}

.fortune-header img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    transition: transform 0.3s ease;
    border-radius: 50%;
    border: 3px solid #A7C7E7;
}

.fortune-header h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #4A6A85;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-family: 'Poppins', sans-serif;
    opacity: 0;
    animation: fadeInFromTop 1s forwards;
}

.fortune-header img:hover {
    transform: scale(1.1);
}

/* Fortune Details */
.fortune-details {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 18px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    color: #333;
    animation: fadeInFromLeft 1s ease-out;
}

/* Highlight Text */
.highlight {
    color: #FF80AB;
    font-weight: bold;
}

/* Fortune Sections */
.fortune-section {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInFromRight 1s ease-out;
}

.fortune-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

.fortune-tags {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
}

.fortune2-tags {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 每行最多2个标签 */
    gap: 16px;
}

.fortune-tags p {
    font-size: 0.95rem;
    color: #688FB5;
    background: rgba(104, 143, 181, 0.2);
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background 0.3s ease;
}

.fortune2-tags p {
    font-size: 0.95rem;
    color: #688FB5;
    background: rgba(104, 143, 181, 0.2);
    padding: 8px 16px;
    border-radius: 8px;
    text-align: left;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background 0.3s ease;
}

.fortune-tags p:hover {
    background: rgba(104, 143, 181, 0.3);
    transform: translateY(-3px);
}

.fortune2-tags p:hover {
    background: rgba(104, 143, 181, 0.3);
    transform: translateY(-3px);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInFromTop {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInFromRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .constellation-container {
        padding: 4vw; /* 调整 padding */
    }

    .constellation-selection {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* 更小的宽度 */
    }

    .constellation-item img {
        width: 50px;
        height: 50px;
    }

    .fortune-header h2 {
        font-size: 1.6rem;
    }

    .fortune-details {
        padding: 15px;
    }

    .fortune-section {
        padding: 15px;
    }

    .fortune-tags p {
        font-size: 0.85rem;
        padding: 6px 12px;
    }

    .fortune2-tags p {
        font-size: 0.85rem;
        padding: 6px 12px;
    }
    .fortune2-tags {
        grid-template-columns: repeat(1, 1fr); /* 在小屏幕上也保持2个标签一行 */
    }
}

@media (max-width: 480px) {
    .constellation-container {
        padding: 5vw;
    }

    .constellation-selection {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* 适应更小设备 */
    }

    .constellation-item {
        padding: 12px;
    }

    .fortune-display {
        padding: 10px;
    }

    .fortune-header h2 {
        font-size: 1.4rem;
    }


}

</style>
<script>
import {getZodiacSign} from "@/utils/constellatiUtil";
import {getTimeyyyyMMddHHmm} from "@/utils/parse";
import {getConstellationFortune} from "@/api/yunoApi";

export default {
    name: "Constellation",
    data() {
        return {
            isShow: true,
            zodiacSign: null, // 当前选中的星座
            constellation: [
                {
                    zodiacSign: "水瓶座", img: require("@/assets/img/constellation/aquarius.png"),
                    //日期范围
                    range: "1.20-2.18",
                    //主宰星座的特性
                    characteristics: "自由博爱",
                    //所属元素
                    element: "风",
                    //主宰宫位
                    dominatePlace: "第十一宫",
                    //性别属性
                    attributes: "阳性",
                    //最大特点
                    peculiarity: "求知",
                    //主宰行星
                    dominatePlanets: "天王星",
                    //幸运色
                    luckyColor: "古铜色",
                    //吉祥宝石
                    auspiciousGemstones: "黑珍珠",
                    //幸运数字
                    luckyNumber: "22",
                    //可用金属
                    metalsAvailable: "蛋白石",
                    //性格特质
                    personalityTraits: "创新、时尚",
                    //优点
                    merit: "求新求变，好奇心强，博爱，有远见",
                    //缺点
                    shortcoming: "多变，叛逆，倔强，自我中心",
                    //基本特点
                    basicFeatures: "太阳位于水瓶座的人，极富革新精神，具有独特的个性，有着超前的思想，是新思想的开拓者。聪颖脱俗，常有奇异的想法，不按牌理出牌。",
                    //具体特点
                    specificCharacteristics: "水瓶座是黄道宫上的第十一个星座，主宰星为天王星，思想前卫，有开拓精神。太阳落在水瓶座的你，是一个反习俗、不愿随声附和的人，说话和做事全凭自己的兴趣，很有自己的主张，追求独一无二的生活方式，物质享受与精神充实一样重要。个性友善博爱、有创意和远见，善于观察分析。好奇心强，但会用理智分析，常常把强烈的愿望和独立精神融合在一起。喜欢自由自在的生活，随心所欲地思考并做出决定。",
                    //性格分析
                    personalityAnalysis: "你很有才华，喜欢创意，不喜欢一成不变的工作，重视逻辑思考的过程，在工作上常常有许多点子，也很喜欢做规划，但对结果却不太在意；在工作上也喜欢与大家分工合作，你动脑策划他人出力。",
                    //感情和人际关系
                    relationship: "你需要注意的是，有时太过于执着，因而显得有点固执，容易以自我为中心；喜欢创新、体现与众不同，也容易夸大问题；太讲求客观，易给人冷漠无情的印象。",
                    //总结
                    summary: "水瓶座的人很聪明，他们最大的特点是创新，追求独一无二的生活，个人主义色彩很浓重的星座。他们对人友善又注重隐私。水瓶座绝对算得上是”友谊之星“，他喜欢结交每一类朋友，但是确很难与他们交心，那需要很长的时间。他们对自己的家人就显得冷淡和疏远很多了。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "双鱼座", img: require("@/assets/img/constellation/pisces.png"),
                    range: "2.19-3.20",
                    characteristics: "浪漫梦幻",
                    element: "水",
                    dominatePlace: "第十二宫",
                    attributes: "阴性",
                    peculiarity: "滥情",
                    dominatePlanets: "海王星",
                    luckyColor: "白色",
                    auspiciousGemstones: "象牙石",
                    luckyNumber: "11",
                    metalsAvailable: "铜",
                    personalityTraits: "感性、多情、艺术",
                    merit: "温柔体贴，感情丰富，气质迷人",
                    shortcoming: "多愁善感，爱幻想，敏感、逃避",
                    basicFeatures: "太阳位于双鱼座的人，内向害羞，有着浓厚的艺术气息。柔情似水，浪漫多情，天真烂漫，会有许多梦幻般的想法，只是有时显得不切实际。",
                    specificCharacteristics: "双鱼座是黄道宫上最后一个星座，主宰星为海王星，注重心灵，非常感性。太阳落在双鱼座的你，性格上体贴、思想脱俗、多才多艺，身上有一种令人难以抗拒的奇异的魅力。你极具浪漫情怀，在知性与感性的冲击下，往往能成为一个无与伦比的艺术家。生性善解人意、坦诚而迷人，喜欢罗曼蒂克的感觉。而且，极富同情心，乐于助人，喜欢奉献，不会随意伤人，看不得他人受到伤害，以及痛苦的表情。",
                    personalityAnalysis: "你擅长抽象的思考，在艺术气息良好的环境中能激发出你的创作灵感，不太喜欢规划，从事与艺术相关的工作较能发挥你的潜能；注重内在感受及精神层面，对现实问题不太在乎；待人友善，没有心机，人际关系良好。",
                    relationship: "你喜欢幻想而容易变得多愁；想得过多有时会悲观，缺乏自信及面对现实的勇气，开创性不足；情绪变动剧烈，较感情用事，易给身边的朋友带来困扰；缺乏决断力和意志力，常受外界干扰，反应力较弱。",
                    summary: "双鱼座是十二宫最后一个星座，他集合了所有星座的优缺点于一身，同时受水象星座的情绪化影响，使他们原来复杂的性格又添加了更复杂的一笔。双鱼座的人最大的优点是有一颗善良的心，他们愿意帮助别人，甚至是牺牲自己。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "白羊座", img: require("@/assets/img/constellation/aries.png"),
                    range: "3.21-4.19",
                    characteristics: "热情活力",
                    element: "火",
                    dominatePlace: "第一宫",
                    attributes: "阳性",
                    peculiarity: "控制",
                    dominatePlanets: "火星",
                    luckyColor: "红色",
                    auspiciousGemstones: "红宝石",
                    luckyNumber: "5",
                    metalsAvailable: "铁",
                    personalityTraits: "活力四射、自我、冲动",
                    merit: "精力充沛，行动为先，极富创造力",
                    shortcoming: "喜怒无常，草率行事，不计后果",
                    basicFeatures: "太阳位于白羊座的人乐观活泼、敢做敢当，干劲十足，属于剑及履及的行动派，是急行侠、探险家。但有时显得没有耐性，冲动易怒，太过冒险。",
                    specificCharacteristics: "白羊座是黄道十二宫的第一个星座，这让你有着孩童般的纯真、坦率，同样，也有着与生俱来的创造力，表现出强烈的领导欲望及企图心。太阳落在白羊座的人是个急先锋，以自我为中心，想到就出发，不爱犹豫；非常享受快节奏的生活步调，冲在最前面才最有优越感。白羊座的主宰行星是火星，在火星的主导之下，羊族人活力充沛，不畏艰险；力争前茅，惟有竞争才能让你感觉到存在的价值；非常乐观，不畏挫折，在人生的舞台上积极追求成功。",
                    personalityAnalysis: "白羊座是属于春天的星座，在万物复苏、春意盎然的季节出生，使得羊族人的体内充满了冒险因子，精力旺盛，思维异常活跃；这些特质都使得你凡事都抢在最前面，一马当先，开创力十足，让人感觉不可超越；为了首开先河，甚至会铤而走险，是个十足的开创型英雄。",
                    relationship: "积极的另一面就是冲动、轻率，太爱冒险、思虑不周，很容易因此而陷入困境，人生大起大落，易把风险当机会；而直肠子的火爆性格常让你得罪人而不自知。站得高、看得远一点，行动前先思虑三秒，多站在他人的立场考虑问题，博采众议、广纳雅言，这些都是羊族的子民们应努力修行的生命议题。",
                    summary: "白羊座有一种让人看见就觉得开心的感觉，因为总是看起来都是那么地热情、阳光、乐观、坚强，对朋友也慷慨大方，性格直来直往，就是有点小脾气。白羊男有大男人主义的性格，而白羊女就是女汉子的形象。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "金牛座", img: require("@/assets/img/constellation/taurus.png"),
                    range: "4.20-5.20",
                    characteristics: "稳健固执",
                    element: "土",
                    dominatePlace: "第二宫",
                    attributes: "阴性",
                    peculiarity: "财富",
                    dominatePlanets: "金星",
                    luckyColor: "粉色",
                    auspiciousGemstones: "翡翠、玉",
                    luckyNumber: "6",
                    metalsAvailable: "木",
                    personalityTraits: "稳定、务实、享受",
                    merit: "沉稳踏实，重视特质，观察力敏锐",
                    shortcoming: "顽固，太过实际，依赖心强",
                    basicFeatures: "太阳位于金牛座的人给人的感觉稳重、务实，追求稳定与和谐，害怕变动，属于享受派。喜欢安定，最怕没有安全感。但有时显得固执己见，对钱财看得很重。",
                    specificCharacteristics: "金牛座是黄道十二宫的第一个星座，这让你有着孩童般的纯真、坦率，同样，也有着与生俱来的创造力，表现出强烈的领导欲望及企图心。太阳落在金牛座的人是个急先锋，以自我为中心，想到就出发，不爱犹豫；非常享受快节奏的生活步调，冲在最前面才最有优越感。金牛座的主宰行星是火星，在火星的主导之下，羊族人活力充沛，不畏艰险；力争前茅，惟有竞争才能让你感觉到存在的价值；非常乐观，不畏挫折，在人生的舞台上积极追求成功。太阳落在金牛座的人追求舒适的生活环境，向往高品质的生活，乐于追求金钱，对美好的物质享受充满欲望。稳定和安全感是你衡量事物的唯一标准，是你执着的追求。做任何事若感觉仍有不确定因素存在，不会轻易地涉入，但一旦决心已定，将全力以赴，九头牛也拉你不动。另外，你对于和身体相关的事物都很感兴趣，包括性爱、饮食、运动等，欣赏一切美丽的事物。敏锐的洞察力也让你很能了解他人的心思，是重感情的人。",
                    personalityAnalysis: "你思虑周全，行事谨慎，拟定计划是你的强项。在行动前就会考虑到前因后果，分析利弊，而后才会择机小心翼翼地投入，要你打没把握的仗比登天还难。",
                    relationship: "你的赚钱欲望强烈，也易赚到钱，但也是一个十足的拜金主义者，易给人铁公鸡一毛不拔的印象，别把钱财看得太重；你行事过于谨慎，易错过良机，应加强行动力，思虑太多易将动力消磨殆尽；有时显得倔强，发起牛脾气来还挺吓人，稍加控制情绪，你的人际关系会更加圆融，提升贵人助力。",
                    summary: "金牛座很保守，喜欢稳定，一旦有什么变动就会觉得心里不踏实，性格也比较慢热，但你是理财高手，对于投资理财都有着独特的见解。金牛男的性格有点儿自我，而金牛女就喜欢投资自己，想要过得更好。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "双子座",
                    img: require("@/assets/img/constellation/gemini.png"),
                    fortune: "今天有很多社交机会",
                    range: "5.21-6.21",
                    characteristics: "花心多变",
                    element: "风",
                    dominatePlace: "第三宫",
                    attributes: "阳性",
                    peculiarity: "思想",
                    dominatePlanets: "水星",
                    luckyColor: "黄色",
                    auspiciousGemstones: "猫眼石",
                    luckyNumber: "7",
                    metalsAvailable: "水银",
                    personalityTraits: "灵活、沟通、口才",
                    merit: "沟通力强，思维活跃",
                    shortcoming: "善辩，多变，难以专精",
                    basicFeatures: "太阳位于双子座的人，思维跳跃，口才伶俐，鬼点子多，常语出惊人，但有三心二意的倾向，情绪起伏多变，难以专心。",
                    specificCharacteristics: "双子座是黄道宫上的第三个星座，受水星守护，思维敏捷，有良好的沟通能力，反应迅速，适应力强。太阳落在双子座的人追求并享受生动、多姿多彩、富于变化的生活环境，平淡、一成不变的环境难以引起你的兴趣。因为你有很强的交际能力，所以身边不缺朋友，风趣机智的表现也常受身边人的欢迎。在好奇心的驱使下，你对新知有着强烈的求知欲，接受力强，因此储备了满腹的新知和话题，让你颇有优越感。",
                    personalityAnalysis: "处变不惊，“变”是你的永恒追求，越是变动的环境越能激起你的挑战神经，如此才刺激，令你感到兴奋，做起事来也更加的起劲。追求变化、有创意才是你的行事风格，为了达到效果，不惜夸大其辞。",
                    relationship: "太阳位于双子座的人具有双重性格，情绪变化快速，做事有虎头蛇尾的倾向，应加强耐性，培养静心的功夫，更专心一些；因为想像力丰富，接收的讯息太多，使得你内心常处于矛盾状态，常有令人不解的举动；凡事据理力争，太好辩，易与人发生言语冲突。",
                    summary: "双子座喜欢追求新鲜感，有点儿小聪明，就是耐心不够，往往做事都是三分钟热度，难以坚持，但是你的可爱性格会让很多人都喜欢跟你做朋友。双子男表面很花心，其实深情，而双子女就喜欢求新和求变。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "巨蟹座", img: require("@/assets/img/constellation/cancer.png"),
                    range: "6.22-7.22",
                    characteristics: "敏感柔情",
                    element: "水",
                    dominatePlace: "第四宫",
                    attributes: "阴性",
                    peculiarity: "感觉",
                    dominatePlanets: "月亮",
                    luckyColor: "绿色",
                    auspiciousGemstones: "珍珠",
                    luckyNumber: "2",
                    metalsAvailable: "金",
                    personalityTraits: "顾家、念旧、关怀",
                    merit: "情感丰富，亲切温暖，善解人意",
                    shortcoming: "多愁善感，沉溺往事，逃避现实",
                    basicFeatures: "太阳位于巨蟹座的人，亲切有礼，感情丰富、细腻，有很强的感受力，具有母性的博爱之心，属于居家派的。但情绪起伏，有逃避倾向。",
                    specificCharacteristics: "巨蟹座是黄道宫上的第四个星座，主宰星为月亮。心思细密，感受力非常强，保护他人的意识强烈，家庭观念浓厚，有强烈的责任心及牺牲奉献精神。太阳落在巨蟹座的人擅长持家，喜欢安定祥和的家庭氛围，对长辈孝顺，对兄弟姐妹、朋友非常友善，努力创造你想要的和谐与美满。当然，你的自我保护的意识也非常强烈，一点点的风吹草动都有可能让你心潮起伏，当他人侵犯了你内心的那块净土时，你也会伸出钳子示威。",
                    personalityAnalysis: "你善于与人共事，凡事喜欢与人分享，行事谨慎，有自己的想法和追求，但不轻易表现出来，更希望他人能感受到你的内心想法。害怕竞争激烈的环境，在和乐的气氛中做事，才能让你感到安心，也能激发出你的潜能。",
                    relationship: "自信、动力不足，遇到挫折易打退堂鼓；心灵较脆弱，经不起打击，情绪低落，常沉迷于自我设想的悲伤里；有逃避心理，害怕面对现实，情绪易跟着他人起舞，过度保护自己。应加强信心，多自我鼓励，卸下心中的包袱。",
                    summary: "巨蟹座的情绪容易敏感，也缺乏安全感，容易对一件事情上心，做事情有坚持到底的毅力，为人重情重义，对朋友、家人都特别忠实，巨蟹男是一等一的好男人，顾家爱家人，巨蟹女充满母性光环，非常有爱心。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "狮子座", img: require("@/assets/img/constellation/leo.png"),
                    range: "7.23-8.22",
                    characteristics: "骄傲威严",
                    element: "火",
                    dominatePlace: "第五宫",
                    attributes: "阳性",
                    peculiarity: "权力",
                    dominatePlanets: "太阳",
                    luckyColor: "红色、黄色",
                    auspiciousGemstones: "琥珀",
                    luckyNumber: "19",
                    metalsAvailable: "黄金",
                    personalityTraits: "王者、自信、大方",
                    merit: "领导力强，很有主见，行动力十足",
                    shortcoming: "自傲，独断，暴躁，欲望强烈",
                    basicFeatures: "太阳位于狮子座的人，自信自强，气度非凡，极具领导统御能力，永不服输，是天生的贵族、王者。只是有时过度追求利，甚至有些自负，莽撞、以自我为中心。",
                    specificCharacteristics: "狮子座是黄道宫上的第五个星座，主宰星为太阳。阳光灿烂，充满活力，感觉有用不完的能量；表现欲强烈，举手投足之间充满了闪耀的魅力，很容易成为众人瞩目的焦点，你身上散发出来的热力很容易感染周围的人，让大家跟着你一起舞动生活。你对权力地位的追求非常执着，以捍卫你的领导地位，而这也是你不断向前的动力泉源。这往往让你站得比别人高，获得也比别人多，但人生大起大落；自尊心很强，当追求无望时会变得沉沦、怠惰。",
                    personalityAnalysis: "有话直说，直截了当，从不拐弯抹角，胸怀坦荡；在工作上展现出高度的自信，做事雷厉风行，挑战权威，在挑战中寻求刺激，获得成就感；待人大方慷慨，野心勃勃，喜欢被人推崇、膜拜，无人关注会感失落。",
                    relationship: "欲望太强，太重面子，得失心较重，骄傲自负，这会让你变得孤立无援；太爱冒险，甚至鲁莽，易损失惨重。舍弃得失心，平和及定性是你需要加强的修为。",
                    summary: "狮子座有着宏伟的理想，总想靠自己的努力成为人上人，你向往高高在上的优越感，也期待被仰慕被崇拜的感觉，有点儿自信有点儿自大。狮子男的大男人气息很重，爱面子，狮子女热情阳光，对朋友讲义气。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "处女座", img: require("@/assets/img/constellation/virgo.png"),
                    range: "8.23-9.22",
                    characteristics: "完美理性",
                    element: "土",
                    dominatePlace: "第六宫",
                    attributes: "阴性",
                    peculiarity: "分析力",
                    dominatePlanets: "水星",
                    luckyColor: "灰色",
                    auspiciousGemstones: "蓝宝石、琥珀",
                    luckyNumber: "7",
                    metalsAvailable: "水银",
                    personalityTraits: "细心、挑剔、分析",
                    merit: "心思缜密，善于分析研究，追求完美",
                    shortcoming: "吹毛求疵，洁癖，保守、严肃",
                    basicFeatures: "太阳位于处女座的人，思虑周全，非常谦虚，谨慎保守，很有耐心，擅长分析，凡事要求做到尽善尽美，是个完美主义者。但易给人较大的压力，较被动严肃。",
                    specificCharacteristics: "处女座是黄道宫的第六个星座，主宰星为水星。思路清晰，冷静理性，待人真诚，但保持一定的距离；对自我要求甚高，脚踏实地默默努力，以达成目标，构筑更美好的生活。受到主宰星水星的影响，你独立，思维敏捷，乐于为人服务，易受人欢迎。生活态度严谨，要求一丝不苟，有自己的道德底线，不会乱来；喜欢安静舒适的环境，最怕无序、杂乱无章；你也希望获得周围人的肯定，听到认同的声音你会更有斗志。",
                    personalityAnalysis: "太阳落在处女座的人做事条理分明，不急躁，稳步前进；细腻的思路和谨慎、耐心的行事作风，让你很容易成为优秀者，在群体中脱颖而出。求知欲望强烈，觉得没有知识是很丢人的事，会拚命地充实自我。",
                    relationship: "太过要求完美，心理承受着较大的压力，容易自我设限，不易放开胸怀；太过小心翼翼，有矫枉过正的嫌疑；太注重细节，往往见树不见林。应释放压力，开阔胸襟，多与人群接触，交流互动可让你收获更多。",
                    summary: "处女座虽然常常被黑，但你还是依然坚持追求自己的完美主义，因为在你看来，生活不能将就，追求的完美更不能将就，有目标才有进步，当然也需要鼓励。处女男的毅力很强，能坚持，处女女的求知欲很强。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "天秤座", img: require("@/assets/img/constellation/libra.png"),
                    range: "9.23-10.23",
                    characteristics: "公平和谐",
                    element: "风",
                    dominatePlace: "第七宫",
                    attributes: "阳性",
                    peculiarity: "衡量抉择",
                    dominatePlanets: "金星",
                    luckyColor: "褐色",
                    auspiciousGemstones: "珊瑚、琥珀",
                    luckyNumber: "3",
                    metalsAvailable: "木",
                    personalityTraits: "平衡、和谐",
                    merit: "优雅，友善，善于交际",
                    shortcoming: "犹豫不决，怕寂寞，以貌取人",
                    basicFeatures: "太阳位于天秤座的人很有气质，谈吐应对得体，善于与人沟通协调，是绝佳的谈判人才，属于迷人优雅派人物。只不过，思想易左右摇摆；追求外表光鲜，易失去自我。",
                    specificCharacteristics: "天秤座是黄道宫上的第七个星座，主宰星为金星。你极具风度，浑身充满了优雅的贵族气息，喜欢与人群接触，不喜欢单打独斗，对于具有美感的事物特别感兴趣，出色的交际能力让你很容易与人打成一片；你很注意自己的外在形象，希望在他人面前表现出完美的一面，但也易让人感觉虚荣、做作。你喜欢从正反两面看待问题，力求公平公正，最怕冲突和不协调；有一套自己人生哲学，懂得享受生活。",
                    personalityAnalysis: "太阳落在天秤座的你，凡事要求公平，遇事从多角度思考，以达到你所追求的平等；热衷于团队合作，在轻松愉快的环境中工作可以激发出你的热情，会有非常出色的表现。只是有时太追求平衡，反而难以达到更好的效果。",
                    relationship: "有时神经过敏，显得神经质，常常处于焦虑不安的状态；意志薄弱，遇事摇摆不定，难以抉择，常给人瞻前顾后的印象，显得懦弱。应放松身心，释放压力，增强魄力与行动力，快速作出决定。",
                    summary: "天秤座常常追求平等、和谐，擅于察言观色，交际能力很强，因此真心朋友不少，因为你也足够真诚，但是最大的缺点就是面对选择总是犹豫不决。天秤男容易在乎自己而忽略别人，天秤女就喜欢被陪伴的感觉。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "天蝎座", img: require("@/assets/img/constellation/scorpio.png"),
                    range: "10.24-11.22",
                    characteristics: "神秘敏锐",
                    element: "水",
                    dominatePlace: "第八宫",
                    attributes: "阴性",
                    peculiarity: "渴望",
                    dominatePlanets: "冥王星",
                    luckyColor: "紫色、黑色",
                    auspiciousGemstones: "碧玉、黑水晶",
                    luckyNumber: "4",
                    metalsAvailable: "铁",
                    personalityTraits: "神秘、冷静、直觉",
                    merit: "明察秋毫，高瞻远瞩，有毅力",
                    shortcoming: "孤僻自怜，占有欲强，有时显得冷漠",
                    basicFeatures: "太阳位于天蝎座的人，外表低调，实则企图心旺盛，充满神秘感，不会轻易地与人互动。对自我要求很高，极具竞争力与战斗力，有洞悉他人心思的能力。",
                    specificCharacteristics: "天蝎座是黄道宫上的第八个星座，主宰星为冥王星，能量强大、精力旺盛，有强烈责任感，韧性强、黑白分明、意志力强、支配欲强烈，对于生命的奥秘有独特的见解。你的城府颇深，待人处事非常冷静理性，不苟言笑，有着强烈的第六感，以及神秘的吸引力，虽然有着敏锐的观察力，但往往仍靠感觉来决定一切。你深沉的面孔常让身边的人感到不舒服，对你敬而远之；很懂得察言观色，但讨厌他人探听你的内心世界。",
                    personalityAnalysis: "凡事自有主张，三思而后行，有着谨慎、冷静的行事风格，非常注重过程，在设想、架构上会花费较多的时间和精力。工作冷静理性，意志坚定，设立目标后不会轻易放弃，会用稳扎稳打的方式实现目标。",
                    relationship: "有孤僻的倾向，不喜欢与人过深的交往，相较于群体活动，更喜欢享受一个人的独处；对他人有不信任感，凡事亲力亲为；较孤傲，表现出很强的戒备心，令人难以亲近。应消除顾忌，再果敢开放一些，拓展人脉，你的人生格局才会更大。",
                    summary: "天蝎座精力旺盛、占有欲极强，对于生活很有目标，不达到目的誓不罢休，复仇心理重，记仇会让自己不顾一切报复曾经伤害过你的人。天蝎男自我主义色彩很强烈，天蝎女的自我保护意识很强，不容易接近。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "射手座", img: require("@/assets/img/constellation/sagittarius.png"),
                    range: "11.23-12.21",
                    characteristics: "自由乐观",
                    element: "火",
                    dominatePlace: "第九宫",
                    attributes: "阳性",
                    peculiarity: "自由",
                    dominatePlanets: "木星",
                    luckyColor: "浅蓝色",
                    auspiciousGemstones: "紫水晶",
                    luckyNumber: "6",
                    metalsAvailable: "锡",
                    personalityTraits: "乐观开朗、自由率真",
                    merit: "风趣幽默，崇尚自由，讲义气",
                    shortcoming: "粗心大意，太爱冒险，浮躁，好吹嘘",
                    basicFeatures: "太阳位于射手座的人，生性乐观开朗，热情奔放，崇尚自由，反应灵敏，极具创造力。待人友善又极具豪气，作风非常海派，人缘颇佳。",
                    specificCharacteristics: "射手座是黄道宫上的第九个星座，主宰星为木星。有着轻松愉快、活泼开朗的人生观。太阳落在射手座，做人非常坦荡，待人真诚热情，积极乐观的一面很吸引人，非常受人欢迎。你属于乐观、坦率、聪慧的类型，喜欢特立独行，颇富直觉与鼓舞他人的力量，思想开明、适应力强，不拘小节，生性幽默，很懂得享受生活。对你而言，生活就是一连串的探险，喜欢追求新奇有趣的经验。",
                    personalityAnalysis: "你很够朋友、讲义气，朋友遍天下，四处有贵人；精力充沛，做事效率高，可以身兼数职，但常常是虎头蛇尾，易半途而废；爱冒险，行动敏捷。",
                    relationship: "你有时做事稍欠考虑，有过于草率之嫌；不守规则，有时还会幽默过头，或是说话太过直接，得罪人而不自知；太过自由，显得散漫；做事求快而不注重细节，易出错，有时还显得三心二意，非常粗心。",
                    summary: "射手座崇尚自由，勇敢、果断、独立，身上有一股勇往直前的劲儿，不管有多困难，只要想，就能做，你的毅力和自信是难以击倒的。射手男酷爱自由，讨厌被束缚，射手女性格简单直接，不耍心计，可是任性。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
                {
                    zodiacSign: "摩羯座", img: require("@/assets/img/constellation/capricornus.png"),
                    range: "12.22-1.19",
                    characteristics: "执著现实",
                    element: "土",
                    dominatePlace: "第十宫",
                    attributes: "阴性",
                    peculiarity: "实际",
                    dominatePlanets: "土星",
                    luckyColor: "咖啡色、黑",
                    auspiciousGemstones: "黑玉",
                    luckyNumber: "4",
                    metalsAvailable: "银",
                    personalityTraits: "企图心、沉稳",
                    merit: "深思熟虑，冷静判断，责任感强",
                    shortcoming: "刻板，保守，孤独，消极",
                    basicFeatures: "太阳位于摩羯座的人，很有自己的想法，很注重实际，耐力十足，意志坚定，有很强的时间观念，重视权威和名声，有不错的组织领导能力，是值得依赖的人。",
                    specificCharacteristics: "摩羯座是黄道宫上的第十个星座，主宰星为土星，有着成熟认真的人生观。太阳落在摩羯座的人，就像将领，能担当大任，追求高难度的理想，喜欢控制全局，善于独立实现自己心中的理想。喜欢从容不迫、深思熟虑，待人亲切和蔼、心胸宽广、有抱负，以踏实的方式追求成功，信奉有付出就会有回报的信条。一步一个脚印，即使遇到挫折也能坚持下去，顽强的毅力及耐力是你迈向成功的不二法门。",
                    personalityAnalysis: "你做事脚踏实地，行事低调深沉，竭尽全力去超越跑在前面的人，不会存有侥幸心理。善于计划，而且按部就班的执行；任何事都讲求公平合理，凡事能运筹帷幄，从一而终，令人放心且欣赏。",
                    relationship: "你比较严肃，不容易亲近；有时过于坚持己见，会有点顽固；内心有着极度的不安全感，对他人不太信任；情绪较压抑，容易给自己施加太大的压力。应放轻松一些，别让神经总是处于紧张状态，休息才能走更长远的路。",
                    summary: "摩羯座是十二星座中最有耐心，为事最小心、也是最善良的星座。他们做事脚踏实地，也比较固执，不达目的是不会放手的。他们的忍耐力也是出奇的强大，同时也非常勤奋。他们心中总是背负着很多的责任感，但往往又很没有安全感，不会完全地相信别人。",
                    todayFortune: [],
                    tomorrowFortune: [],
                    weekFortune: [],
                    monthFortune: [],
                    yearFortune: []
                },
            ],
        };
    },
    methods: {
        selectZodiacSign(item) {
            this.zodiacSign = item;
            this.isShow = false
        },
        getZodiacSignInfo() {
            const zodiacName = getZodiacSign();
            // 使用 find 方法查找 constellation 数组中匹配的星座对象
            this.zodiacSign = this.constellation.find(sign => sign.zodiacSign === zodiacName);
            // this.current = this.zodiacSign
            // this.current.currentTime = getTimeyyyyMMddHHmm()
        },
        getConstellation() {
            getConstellationFortune().then(res => {
                res.forEach(v => {
                    const matched = this.constellation.find(item => item.zodiacSign === v.constellation);
                    matched.todayFortune = v.today
                    matched.tomorrowFortune = v.tomorrow
                    matched.weekFortune = v.week
                    matched.monthFortune = v.mouth
                    matched.yearFortune = v.year
                    matched.yearFortune.summary = matched.yearFortune.summary.replaceAll("[\"", "").replaceAll("\"]", "")
                })
                console.log(this.constellation[0])
            })
        }
    },
    mounted() {
        this.getConstellation()
        this.getZodiacSignInfo()
    },
};
</script>