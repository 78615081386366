<template>
    <div class="family-motto-list">
        <back></back>
        <h1>记住那天关于光阴的教训   回头走天已暗</h1>
        <ul>
            <li v-for="motto in mottos" :key="motto.id" class="motto-item">
                {{ motto.info }}
            </li>
        </ul>
        <input type="text" class="motto-input" placeholder="添加" v-model="addMottoInfo" @keyup.enter="addMotto()" @blur="addMotto">

        <div class="bottomPower">powered by 颤颤巍巍</div>
    </div>
</template>

<script>
import {addMotto, getFamilyMotto} from "@/api/yunoApi";
import Back from "@/components/back.vue";

export default {
    name: "familyMotto",
    components: {Back},
    data() {
        return {
            mottos: [],
            pageNum: 1,
            totalCount: 1,
            isFetching: false, // 新增变量控制加载状态
            addMottoInfo: '',
        };
    },
    methods: {
        handleScroll() {
            if (this.isFetching) return; // 如果正在加载，则不执行
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 200) { // 距离底部100像素时触发
                this.pageNum++; // 增加页码
                this.getMotto(); // 请求下一页数据
            }
        },
        getMotto() {
            if (this.isFetching || this.mottos.length >= this.totalCount) return; // 如果正在加载，则不执行
            this.isFetching = true; // 设置加载状态为true
            getFamilyMotto(this.pageNum).then(res => {
                this.mottos = [...this.mottos, ...res.list];
                this.totalCount = res.totalCount
                this.isFetching = false; // 请求完成后设置加载状态为false
            })
        },
        addMotto() {
            if (this.addMottoInfo !== '' && this.mottos != null) {
                const info = {info: this.addMottoInfo}
                addMotto(info).then(res => {
                    this.mottos = []
                    this.addMottoInfo = ''
                    getFamilyMotto(1).then(res => {
                        this.mottos = [...this.mottos, ...res.list];
                        this.totalCount = res.totalCount
                        this.isFetching = false; // 请求完成后设置加载状态为false
                    })
                })
            }
        }
    },
    mounted() {
        this.getMotto()
    }
}
</script>

<style scoped>
/* 使用Google Fonts引入一个现代字体 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.family-motto-list {
    font-family: 'Roboto', sans-serif; /* 设置字体 */
    padding: 20px 50px;
    background: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%); /* 浅蓝色到深蓝色渐变背景 */
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* 轻微阴影 */
    min-height: 90vh;
    color: #333; /* 文字颜色 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* 顶部对齐 */
}

.family-motto-list h1 {
    position: relative;
    text-align: center;
    color: rgba(51, 51, 51, 0.6); /* 使用半透明的深灰色 */
    margin: 30px 0; /* 调整标题的上下边距 */
    font-weight: 700; /* 加粗标题 */
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* 添加阴影以制造朦胧效果 */
    font-style: italic; /* 斜体字 */
    font-size: 1.8rem; /* 增大字体大小 */
}

ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 800px; /* 设置最大宽度 */
}

.motto-input {
    opacity: 0.2;
    padding: 20px 25px;
    margin-bottom: 15px;
    background-color: #ffffff; /* 白色背景 */
    border: 1px solid #eaeaea; /* 轻微边框 */
    border-radius: 8px;
    font-size: 1.1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: text; /* 输入框的鼠标样式 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px; /* 设置最大宽度 */
    /* 去掉下边框，因为输入框不需要 */
    border-bottom: none;
}

.motto-item {
    padding: 20px 25px;
    margin-bottom: 15px;
    background-color: #ffffff; /* 白色背景 */
    border: 1px solid #eaeaea; /* 轻微边框 */
    border-radius: 8px;
    font-size: 1.1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 动画效果 */
    cursor: pointer; /* 鼠标样式 */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.motto-item:hover {
    transform: translateY(-3px); /* 悬浮时轻微上移 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 悬浮时阴影加深 */
}

.motto-item:last-child {
    margin-bottom: 0;
}

.bottomPower {
    position: fixed; /* 固定位置 */
    bottom: 20px;
    right: 20px;
    font-style: italic;
    color: #666; /* 较深灰色文本 */
    opacity: 0.8;
    font-size: 0.9rem;
    z-index: 10; /* 确保在最上层 */
}
</style>